<template>
  <div
    class="border-radius-8 col-dark-blue  bg-transparent p-dropdown-trigger-col"
    style="display: flex; flex-direction: column; justify-content: space-between; position: relative">
    <Dropdown
      class="col-dark-blue language-select-dropdown"
      v-model="innerModelValue"
      style="display: flex; align-items: center; height: 50px;"
      :options="[
        { name: 'en', value: 'United Kingdom' },
        { name: 'de', value: 'Germany' },
      ]"
      @change="
        $emit('update:modelValue', innerModelValue);
        change ? change($event) : '';">
      <template #value="slotProps">
        <div
          v-if="slotProps.value && slotProps.value.value"
          class="w-6 h-6"
          style="display: flex; align-items: center; justify-content: center; padding-bottom: 5px">
          <Flag :countryFlag="slotProps.value.value"/>
        </div>
        <span v-else>
          {{slotProps.placeholder}}
        </span>
      </template>
      <template #option="slotProps">
        <div style="display: flex; align-items: center; justify-content: center">
          <Flag :countryFlag="slotProps.option.value"/>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script src="./MultiLanguageDropdown.ts"></script>

<style>
.p-dropdown-items {
  box-shadow: 0px 0px 5px 5px var(--dark-blue-30);
}

.p-dropdown-items-wrapper {
  overflow: visible;
}
</style>
