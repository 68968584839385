<template>
  <div
    style="z-index: 1; top: 0; height: 100%; width: 100%; position: absolute"
  >
    <div
      style="
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div style="padding-top: 30px; display: flex; align-items: center; flex-direction: column">
        <img :src="getHeaderUrl()"
             style="border: none; height: 90px" alt="Logo"/>
        
        <div
          class="col-menu-color bg-tt-white border-radius-50"
          style="
	          display: flex;
	          flex-direction: column;
	          gap: 30px;
	          align-items: center;
	          justify-content: center;
	          box-shadow: 20px 20px 50px;
	          border-color: transparent;
	          padding: 50px;
	          margin-top: 36px;
	          height: 570px;
	          width: 360px;
	          min-width: 360px;
	          transition: all 0.2s;
	        "
          :style="isRegister ? ' width: 700px;' : ''"
        >
          <div
            style="
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            height: 175px;
            width: 175px;
            padding: 10px;
            background-color: var(--logo-background);
            box-shadow: 4px 3px 20px;
          "
            class="bor-light-blue"
          >
            <img :src="getLogoUrl()" style="max-width: 90%; max-height: 90%" class="bor-transparent"/>
          </div>
          
          <div
            style="
              margin-top: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;"
          >
            <form v-if="!isRegister">
              <div
                style="
                  overflow: auto;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
							>
								<CustomInputText
									v-model="username"
									label="Username"
									@keypress="keyPressedUsername"
									@input="
                    submitted = false;
                    valid = true;
                  "
                  @change="
                    submitted = false;
                    valid = true;
                  "
									:class="submitted && !valid ? 'bor-reject' : ''"
									style="width: 255px; height: 50px"
								/>
								<CustomInputText
									customId="passwordField"
									v-model="password"
									label="Password"
									type="password"
									@keypress="keyPressedPassword"
									@input="
                    submitted = false;
                    valid = true;
                  "
									@change="
                    submitted = false;
                    valid = true;
                  "
									:class="submitted && !valid ? 'bor-reject' : ''"
									style="width: 255px; height: 50px; margin-top: 24px"
								/>
								<div
									v-if="submitted && !valid"
									class="col-reject"
									style="margin-top: 20px"
								>
									invalid username/password
								</div>
							</div>
						</form>
						
						<form v-else>
							<!-- These two elements catch the autofill from the browser so we dont have to deal with this -->
							<InputText type="email" style="opacity: 0; height: 0; width: 0; position: absolute"></InputText>
							<InputText type="password" style="opacity: 0; height: 0; width: 0; position: absolute"></InputText>
							
							<div
								v-if="!registrationValid"
								style="
										height: 170px;
                    display: grid;
                    gap: 15px;
									  grid-template-columns: repeat(2, 1fr);
									  grid-template-rows: repeat(2, 1fr);
                "
              >
                <CustomInputText
                  v-model="firstName"
                  :label="$t('general.firstName')"
                  :class="submitted && !valid && !firstName ? 'bor-reject' : ''"
                  style="width: 255px; height: 50px"
                />
                
                <CustomInputText
                  v-model="lastName"
                  :label="$t('general.surname')"
                  :class="submitted && !valid && !lastName ? 'bor-reject' : ''"
                  style="width: 255px; height: 50px"
                />
                
                <CustomInputText
                  v-model="password"
                  label="Password"
                  type="password"
                  :class="submitted && !valid ? 'bor-reject' : ''"
                  style="width: 255px; height: 50px"
                />
                
                <CustomInputText
                  v-model="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  :class="submitted && !valid ? 'bor-reject' : ''"
                  style="width: 255px; height: 50px"
                />
                
                <CustomInputText
                  v-model="email"
                  :label="$t('general.eMail')"
                  :class="submitted && !valid && !email ? 'bor-reject' : ''"
                  style="width: 255px; height: 50px"
                />
              </div>
              <div v-else>
                <div class="flex-center col-dark-blue" style="height: 170px; gap: 30px; flex-direction: column">
                  {{$t('login.waitingForEmailVerification')}}
                  {{email}}
                </div>
              </div>
            </form>
            
            <div>
              <Button
                v-if="isRegister"
                @click="isRegister = false"
                :label="$t(registrationValid ? 'general.ok' : 'general.cancel')"
                class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-tt-white"
                style="
                width: 255px;
                height: 45px;
                padding: 7px 6px 6px 8px;
                margin-top: 25px;
                margin-right: 15px;
              "
              />
              
              <Button
                v-if="!registrationValid"
                @click="onClickLoginOrRegister"
                :label="$t(isRegister ? 'general.register' : 'general.login')"
                class="border-radius-12 col-white bor-transparent bg-dark-blue"
                style="
                width: 255px;
                height: 45px;
                padding: 7px 6px 6px 8px;
                margin-top: 25px;
              "
              />
            </div>
            
            <div v-if="!isRegister && allowCreateAccount" @click="isRegister = true"
                 class="flex-center underline-on-hover"
                 style="border-top: 1px solid var(--background); cursor: pointer; width: 100%; margin: 15px 15px 0 15px; padding:  15px 15px 0 15px">
              {{$t('login.createAccount')}}
            </div>
          </div>
        </div>
      </div>
      
      <pDialog header="Email verified" :visible="registrationCompleteDialog">
        <div style="height: 150px; width: 400px; flex-direction: column" class="flex-center">
          <div>
            {{firstName}} {{lastName}}, your email has been verified!
          </div>
          <div>
            To login use '{{username}}'
          </div>
        </div>
        <div style="width: 100%" class="flex-center">
          <Button
            @click="onClickRegistrationDone()"
            :label="$t('general.close')"
            class="border-radius-12 col-white bor-transparent bg-dark-blue"
            style="width: 255px; height: 45px; padding: 7px 6px 6px 8px; margin-top: 25px"
          />
        </div>
      </pDialog>
      <div
        style="padding-bottom: 20px; padding-top: 10px; font-size: 15px; display: flex; flex-direction: column; align-items: center"
        class="col-dark-blue-30">
        <div>
          {{brandText}}<br/>
        </div>
        <div style="display: flex; flex-direction: row; cursor: pointer; gap: 10px; font-size: 13px">
          <div @click="showPrivacy = !showPrivacy">
            Privacy Policy
          </div>
          |
          <div @click="showChangelog = !showChangelog">
            Changelog
          </div>
        </div>
      </div>
    
    </div>
  </div>
  
  <pDialog :header="'Datenschutzerklärung'"
           v-model:visible="showPrivacy"
           :draggable="false"
           :maximizable="true"
           :modal="true">
    <PrivacyPolicy/>
  </pDialog>
  
  <pDialog :header="'Changelog'"
           v-model:visible="showChangelog"
           style="width: 80%"
           :draggable="false"
           :maximizable="true"
           :modal="true">
    <Changelog/>
  </pDialog>
</template>

<script src="./LoginView.ts"></script>
