<template>
  <Transition name="slide-fade">
    
    <div
      v-if="show"
      class="list-item-hover"
      @click="onClick($event)"
      style="
      display: flex;
      width: 99%;
      margin: 5px 5px 16px 5px;
      cursor:pointer;
      border-radius:16px;"
    >
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 2%;
        display: flex;
        align-items: center;
        height: 80px;
        border-radius: 16px 0px 0px 16px;
        border-right-width: 0px;
      "
      ></div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 180px;
        display: flex;
        align-items: center;
        border-width: 1px 0 1px 0;
      "
      >
        
        
        <div style="overflow: hidden; border-radius: 10px"
             class="box-shadow-2"
             @mouseleave="player.pause()"
             @mouseenter="player.play()"
             v-if="!item.status || (item.status.toUpperCase() !== 'APPROVED' && item.status.toUpperCase() !== 'REJECTED')">
          
          <video
            class="video-js"
            ref="videoPlayerRef"
            style="
                  width: 122px;
                  height: 70px;
                  background: transparent;
                  pointer-events: none;
                "
            muted
          />
        </div>
        
        <RoundedLabel v-else :text="item.status" :class="getBorderColor(item.status)"/>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 30%;
        display: flex;
        align-items: center;
        border-width: 1px 0px 1px 0px;
        cursor: pointer;
      "
      >
        <div :style="getOpacity(item.status)" style="width:100%;">
          <div
            :style="
            !hasPermission('approval_view/click_items/to_be_approved') ? 'color: lightgray' : ''
          "
            style="line-height: 20px; width: 400px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          >
            {{item.assetName}}
          </div>
        </div>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 15%;
        display: flex;
        align-items: center;
        border-width: 1px 0px 1px 0px;
      "
      >
        <div :style="getOpacity(item.status)">
          <div style="line-height: 20px; margin-left: 10px">
            {{getCompletedText(item.completed)}}
          </div>
        </div>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 15%;
        display: flex;
        align-items: center;
        border-width: 1px 0px 1px 0px;
      "
      >
        <div :style="getOpacity(item.status)" style="width: 150px">
          <RoundedLabel
            @click="
            $store.commit('ADD_FILTER_BREADCRUMB', {
              name: item.language,
              type: 'LANGUAGE',
            });
            $emitter.emit('search-asset-items', null);
          "
            :countryFlag="item.language"
            :text="item.language"
            languageCode="true"
            class="bor-menu-color"
            style="width: 100px; cursor: pointer"
          />
        </div>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 20%;
        display: flex;
        align-items: center;
        border-width: 1px 0px 1px 0px;
      "
      >
        <div :style="getOpacity(item.status)" style="width: 150px">
          <RoundedLabel
            v-if="item.groupID"
            @click="
            $store.commit('ADD_FILTER_BREADCRUMB', {
              name: item.groupIds ? item.groupIds : item.groupID,
              type: 'GROUP',
            });
            $emitter.emit('search-asset-items', null);
          "
            class="bor-menu-color"
            style="cursor: pointer; width: 130px"
            :lineThrough="isGroupDeleted"
            :text="item.groupID"
          />
        </div>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 20%;
        display: flex;
        align-items: center;
        border-width: 1px 0px 1px 0px;
      "
      >
        <div :style="getOpacity(item.status)">
          <RoundedLabel
            v-if="item.translatorID"
            @click="
            $store.commit('ADD_FILTER_BREADCRUMB', {
              name: item.translatorID,
              type: 'TRANSLATOR',
            });
            $emitter.emit('search-asset-items', null);
          "
            class="bor-menu-color"
            style="cursor: pointer"
            :text="item.translatorID"
          />
        </div>
      </div>
      <div
        :class="'bg-tt-white ' + getBorderColor(item.status)"
        style="
        width: 2%;
        display: flex;
        align-items: center;
        height: 80px;
        border-radius: 0px 16px 16px 0px;
        border-left-width: 0px;
      "
      ></div>
    </div>
  
  </Transition>
</template>

<script src="./ListJobApprovalItem.ts"></script>
