import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref, VueElement, watch} from "vue";
import {useStore} from "vuex";
import {
  Asset,
  AssetType,
  SearchAssetsObject,
  User,
  UserRole
} from "../../../target/api/de/moovit/titletoolserver/model";
import {UserApi, AssetApi} from "../../../target/api";
import {useRoute} from "vue-router";
import {AxiosResponse} from "axios";

export default defineComponent({
  components: {},
  setup: function (props, context) {
    const store = useStore();
    const route = useRoute();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi = new AssetApi();
    const userApi = new UserApi();

    let isListView = ref(false);
    let searchText = ref("");
    let sortColumn = ref("CREATED");
    let sortAsc = ref(false);
    let assets: any = ref([]);
    let assetsRef: any | undefined;
    let page: number = 0;
    let pageSize: number = 20;
    let addingAssets = ref(false);
    let added0Assets = ref(false);

    let getAssets: any = async () => {
      let searchAssetsObject: SearchAssetsObject = {};
      searchAssetsObject.page = page;
      searchAssetsObject.pageSize = pageSize;
      searchAssetsObject.searchText = searchText.value;
      searchAssetsObject.assetType = AssetType.MASTER;
      searchAssetsObject.ascSort = sortAsc.value;
      searchAssetsObject.sortColumn = sortColumn.value.toLowerCase();

      searchAssetsObject.groups = [];
      searchAssetsObject.languages = [];
      searchAssetsObject.customFilter = [];

      for (let i in store.state.filterBreadcrumbs) {
        let filterBreadcrumb = store.state.filterBreadcrumbs[i];
        console.log("filterBreadcrumb", filterBreadcrumb);

        if (filterBreadcrumb.type == "GROUP") {
          if (filterBreadcrumb.name instanceof Array) {
            for (let group of filterBreadcrumb.name) {
              searchAssetsObject.groups.push(group);
            }
          }
        }
        if (filterBreadcrumb.type == "LANGUAGE") searchAssetsObject.languages.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "CUSTOM") searchAssetsObject.customFilter.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "ID") searchAssetsObject.thirdPartyID = filterBreadcrumb.name;
      }

      let user: User = (await userApi.getUserById(JSON.parse(localStorage.loginData).user.id)).data;
      let userIsAdmin: boolean = user.role == UserRole.ADMIN;

      if (!userIsAdmin && user && user.groups) {
        for (const group of user.groups) {
          if (group.id) {
            searchAssetsObject.groups.push(group.id);
          }
        }
      }

      let searchAssetsResponse = await assetApi.searchAssets(searchAssetsObject);
      if (searchAssetsResponse) {
        console.debug("AssetsView - ITEMS", searchAssetsResponse.data);
        return searchAssetsResponse.data;
      }
    };

    let searchAssets = async () => {
      page = 0;
      assets.value = [];
      assets.value = await getAssets();
      if (assetsRef) assetsRef.scrollTop = 0;
      added0Assets.value = false;
    };

    let addAssets = async () => {
      if (addingAssets.value || added0Assets.value) return;

      addingAssets.value = true;
      page++;

      let newAssets: any = await getAssets();
      assets.value = assets.value.concat(newAssets);
      console.debug("ADDED " + newAssets.length + " ASSETS");

      if (newAssets.length == 0) {
        added0Assets.value = true;
      }

      addingAssets.value = false;
    };

    let onBlurSearchField = () => {
      searchAssets();
    };

    let handleScroll = (event: any) => {
      let scrollMax = event.target.scrollHeight - event.target.clientHeight;
      let scrollPos = event.target.scrollTop;

      if (scrollMax - scrollPos < 150 && !addingAssets.value) {
        addAssets();
      }
    };

    watch(isListView, () => {
      searchAssets();
    });

    onMounted(async () => {
      emitter.on("search-assets", () => {
        searchAssets();
      });

      emitter.on("update-asset", (id: string, callback: any) => {
        assetApi
          .getAssetById(id)
          .then((response: AxiosResponse<Asset>) => {
            if (response.data) {
              assets.value.forEach(function (item: Asset, i: number) {
                if (item.id === id) {
                  assets.value[i] = response.data;
                  emitter.emit("loadVideo" + item.id, response.data);
                  console.debug("Refresh completed");
                }
              });
            }
          })
          .catch((err: any) => {
            console.debug(err);
          });
      });

      const assetId: any = route.query.id;

      if (assetId) {
        let resp: any = await assetApi.getAssetById(assetId);
        //console.debug('resp', resp.data);
        assets.value = [resp.data];
        page = 0;
        if (assetsRef) assetsRef.scrollTop = 0;
        added0Assets.value = false;
      } else {
        searchAssets();
      }

      let tAssets = {
        t: "activityBreadcrumbs.assets",
        e: undefined,
      };
      store.commit("SET_ACTIVITY_BREADCRUMBS", [tAssets]);

      assetsRef = document.getElementById("assetsRef");
      assetsRef.addEventListener("scroll", handleScroll);

      let localIsListView = localStorage.getItem("isListView");
      if (localIsListView) {
        isListView.value = JSON.parse(localIsListView);
      }
    });

    onUnmounted(() => {
      emitter.off("search-assets");
      if (assetsRef) assetsRef.removeEventListener("scroll", handleScroll);
    });

    return {
      assets,
      isListView,
      searchText,
      sortColumn,
      sortAsc,
      onBlurSearchField,
      addingAssets,
      added0Assets,
    };
  },
});
