<template>
  <div
    class="col-menu-color bg-tt-white border-radius-16"
    style="
      width: 100%;
      box-shadow: 20px 20px 50px;
      margin-right: 24px;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("addAsset.addNewAsset")}}
      </div>
      
      <div
        v-if="currentPage === 1"
        style="
          width: 100%;
          height: calc(100vh - 300px);
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div
          class="col-dark-blue"
          style="
            min-height: 60px;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
          "
        >
          {{$t("addAsset.needToCollectFiles")}}
        </div>
        <div
          v-if="!currentFile"
          class="border-radius-24 bg-light-blue bor-dark-blue col-dark-blue p-16 m16"
          style="
            border-style: dashed;
            border-width: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div/>
          <div style="text-align: center">
            {{$t("addAsset.dragDropZipFileOrBrowse")}}
          </div>
          <Button
            @click="fileInput.click()"
            :label="$t('general.browseFiles')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue mt-10"
            style="width: 180px; height: 40px"
          />
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept=".zip,.mp4"
            @change="onFilePicked"
          />
          <div/>
        </div>
        <div
          v-if="currentFile"
          class="border-radius-24 bg-menu-color bor-dark-blue col-dark-blue p-16 m16"
          style="
            border-style: dashed;
            border-width: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div/>
          <i class="pi pi-file-o col-white" style="font-size: 56px"></i>
          <div
            style="
              text-overflow: ellipsis;
              text-align: center;
              overflow: hidden;
            "
          >
            {{currentFile.name}}
          </div>
          <div class="mt-10" style="display: flex; align-items: center">
            <Button
              @click="fileInput.click()"
              :label="$t('general.browseFiles')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="
                width: 190px;
                height: 40px;
                padding: 7px 6px 6px 8px;
                margin-right: 24px;
              "
            />
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept=".zip,.mp4"
              @change="onFilePicked"
            />
            <Button
              @click="
                submit()
                //$emit('update:transitionDir', 'slide-right');
                //$emit('update:currentPage', currentPage + 1);
              "
              :label="$t('general.upload')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="width: 190px; height: 40px; padding: 7px 6px 6px 8px"
            />
          </div>
          
          <div/>
        </div>
      </div>
      
      <div
        v-if="currentPage === 2"
        style="
          width: 100%;
          height: calc(100vh - 300px);
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          padding-top: 100px;
          justify-content: center;
        "
      >
        <div style="flex-direction: column">
          <div class="flex-center" style="flex-direction: column">
            <div
              v-if="currentFile"
              class="col-dark-blue flex-center"
              style="margin-bottom: 12px; text-align: center; max-width: 400px"
            >
              {{currentFile.name}}
            </div>
            <div
              class="border-radius-150 bg-complete-light col-complete box-shadow-3"
              style="
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 240px;
                height: 240px;
                padding: 12px;
              "
            >
              <div style="display: flex">
                <div style="position: relative; width: 220px; height: 220px">
                  <VueCircle
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 220px;
                      height: 220px;
                    "
                    v-bind:class="{ spin: currentStatus3 === 'UNKNOWN' }"
                    v-model:percent="currentProgress3"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#1BD566"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 12.5px;
                      left: 12.5px;
                      width: 195px;
                      height: 195px;
                    "
                    v-bind:class="{ spin: currentStatus2 === 'UNKNOWN' }"
                    v-model:percent="currentProgress2"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#6DF38A"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 24px;
                      left: 24px;
                      width: 172px;
                      height: 172px;
                      background: transparent;
                    "
                    v-bind:class="{ spin: currentStatus1 === 'UNKNOWN' }"
                    v-model:percent="currentProgress1"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#FFC7BB"
                    :border-width="8"
                  />
                  <VueCircle
                    style="
                      position: absolute;
                      top: 35px;
                      left: 35px;
                      width: 150px;
                      height: 150px;
                      background: transparent;
                    "
                    v-bind:class="{ spin: currentStatus0 === 'UNKNOWN' }"
                    v-model:percent="currentProgress0"
                    :show-percent="false"
                    empty-color="transparent"
                    fill-color="#FFAC99"
                    :border-width="8"
                  />
                  
                  <div
                    style="position: absolute; display: flex; flex-direction: column; align-items: center; justify-content: center; top: 80px; width: 220px">
                    <div
                      v-if="!allStagesDone && !error"
                      style="
                        color: #ffbdae;
                        font-size: large;
                        text-align: center;
                      "
                    >
                      {{message}}
                    </div>
                    <div
                      v-if="!allStagesDone && !error"
                      style="
                        color: #ffbdae;
                        font-size: xx-large;
                        padding-top: 10px;
                        text-align: center;
                      "
                    >
                      {{currentProgress}}%
                    </div>
                    <div
                      v-if="allStagesDone"
                      style="
                        color: #47df84;
                        font-size: x-large;
                        text-align: center;
                        margin-top: 15px;
                      "
                    >
                      Completed
                    </div>
                    <div
                      v-if="error"
                      style="
                        color: #e31d1c;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        font-size: x-large;
                        height: 60px;
                        width: 120px;
                      "
                      v-tooltip="message"
                    >
                      {{message.slice(0, 13)}} ...
                    </div>
                    <!-- <div style="color:#4C47DF">Render: {{ currentProgress }} %</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center col-dark-blue" style="width: 100%; height: 20px; margin-top: 15px">
              {{allStagesDone ? 5 : currentStage + 1}} / 5
            </div>
          </div>
          <div
            class="col-dark-blue"
            style="
              min-height: 60px;
              font-size: 16px;
              font-weight: 300;
              text-align: center;
            "
          >
            {{completedMessage}}
          </div>
        </div>
        
        <div
          v-if="!isMobile"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
          "
        >
          <div
            class="col-dark-blue"
            style="
              min-height: 60px;
              font-size: 16px;
              font-weight: 300;
              text-align: center;
            "
          >
            {{$t("addAsset.helpAssetManagerIdentifyAsset")}}
          </div>
          <div style="height: 100%">
            <div
              style="height: inherit"
              class="grid-holder"
            >
              <CustomInputText
                :label="$t('general.id')"
                v-model="currentId"
                class="
                  border-radius-12
                  col-dark-blue
                  bor-dark-blue-60
                  bg-transparent
                  grid-item m-5
                "
              />
              <CustomInputText
                :label="$t('general.projectName')"
                v-model="currentProjectName"
                class="
                  border-radius-12
                  col-dark-blue
                  bor-dark-blue-60
                  bg-transparent
                  grid-item m-5
                "
              />
              <AutoCompleteLanguage
                v-model="currentLanguage"
                class="
                  border-radius-12
                  col-dark-blue
                  bor-dark-blue-60
                  bg-transparent
                  grid-item m-5
                "
              />
              
              <MultiSelect v-model="currentGroups"
                           :options="allGroups"
                           :placeholder="$t('general.groups')"
                           filter
                           style="width: 280px; height: 50px"
                           class="grid-item m-5 border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"/>
              
              
              <div v-for="metadata in customerMetadata">
                <CustomerMetadataInput :metadata="metadata" class="grid-item m-5"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- FOOTER -->
      
      <div
        style="
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <Button
            v-if="error || (currentAssetId && currentStage !== 2 && (currentStage !== 3 || allStagesDone))"
            @click="onClickCancel"
            :label="$t('general.cancel')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 90px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
        <div
          style="
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
        </div>
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <Button
            v-if="allStagesDone"
            @click="onClickNext"
            :label="$t('general.next')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 90px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./AddAssetViewChooseFile.ts"></script>
